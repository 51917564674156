import React from 'react'
import { SlickSlider } from '../../components'
import photo1 from '../../img/vr-horizon-plan-1.jpg'
import photo2 from '../../img/vr-horizon-plan-2.jpg'
import photo3 from '../../img/vr-horizon-plan-3.jpg'
import photo4 from '../../img/vr-horizon-plan-4.jpg'
import photo5 from '../../img/vr-horizon-plan-5.jpg'
import photo6 from '../../img/vr-horizon-plan-6.jpg'
import photo7 from '../../img/vr-horizon-plan-7.jpg'

export const SliderPlan = props => {
  return (
    <SlickSlider
      slideImgs={[
        {
          photo: photo1,
          containerKey: 'photo-1-container',
          photoKey: 'photo-1',
        },
        {
          photo: photo2,
          containerKey: 'photo-2-container',
          photoKey: 'photo-2',
        },
        {
          photo: photo3,
          containerKey: 'photo-3-container',
          photoKey: 'photo-3',
        },
        {
          photo: photo4,
          containerKey: 'photo-4-container',
          photoKey: 'photo-4',
        },
        {
          photo: photo5,
          containerKey: 'photo-5-container',
          photoKey: 'photo-5',
        },
        {
          photo: photo6,
          containerKey: 'photo-6-container',
          photoKey: 'photo-6',
        },
        {
          photo: photo7,
          containerKey: 'photo-7-container',
          photoKey: 'photo-7',
        },
      ]}
    />
  )
}
