/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CrookedEffect, ImgAndText } from '../../components'
import photo from '../../img/vr-horizon-v-board.png'
import { Flex } from 'vr-horizon-ui'

export const Boards = () => {
  return (
    <Flex
      css={{
        position: 'relative',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}>
      <ImgAndText
        btnTo="/borden"
        btnText="Info"
        textOrder="3"
        textAnimation="fade-left"
        title="Borden"
        text="Verkoopborden maken/plaatsen
behoort ook tot onze service. Deze borden kunnen wij aanpassen naar uw huisstijl. U krijgt ook gratis toegang tot onze
online tool om de plaatsing op te volgen."
        colOrder="2"
        imgOrder="1"
        imgAnimation="fade-left"
        img={photo}
        imgAlt={'vr-horizon v-bord'}
        justifyText="center"
      />
      <CrookedEffect containerColor="#f8faf8" />
    </Flex>
  )
}
