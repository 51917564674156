import React from 'react'
import {
  IntroBanner,
  ImgAndText,
  TitleAndText,
  ImgComponent,
} from '../../components'

import { Slider } from './Slider'
import { SliderPlan } from './SliderPlan'

import photo from '../../img/vr-horizon-grondplannen-service.jpg'
import photo2 from '../../img/vr-horizon-grondplannen-big-img.jpg'
import photo3 from '../../img/vr-horizon-high-renders.jpg'

export const VisualisationPage = props => {
  return (
    <div id="VisualisationPage">
      <IntroBanner
        img={photo2}
        imgPos={'25%'}
        imgAlt={'vr-horizon-tours-banner'}
        bannerH={'40vh'}
        title={'Visualisatie'}
        blurColor={'#424242'}
      />
      <ImgAndText
        textOrder="3"
        textAnimation="fade-right"
        title="Grondplan"
        text="Uw grondplan verwerken doen we met behulp van Floorplanner, zo maken we een structurele plattegrond. Door onder andere logo’s toe te voegen worden deze basisplannen naar uw wens en op maat gemaakt."
        justifyText="center"
        btnTo="/"
        btnDisplay="none"
        colOrder="2"
        imgOrder="1"
        imgAnimation="fade-right"
        img={photo}
        imgAlt={'vr-horizon-map'}
      />
      <ImgComponent
        img={photo3}
        imgAlt="vr-horizon-renders"
        imgH="50vh"
        imgW="100%"
        imgPos="center"
        parallax="fixed"
      />
      <TitleAndText
        titleOrder="3"
        titleAnimation="fade-left"
        colOrder="2"
        textOrder="1"
        title="Mogelijkheden"
        text="Hebt u reeds afmetingen die nog verwerkt dienen te worden of doet u liever op ons beroep voor het opmeten van uw interieur door onze ervaren fotografen? Beide zijn een optie, wij bekijken dan ook graag met u wat uw mogelijkheden zijn. Zo gebeurt er geen onnodig werk en hebt u geen overbodige kosten. "
      />
      <SliderPlan />

      <TitleAndText
        titleOrder="1"
        titleAnimation="fade-right"
        colOrder="2"
        textOrder="3"
        title="3D Renders"
        text="Wilt u potentiële kopers overtuigen van uw vastgoedproject? Dit is perfect mogelijk door gebruik te maken van 3D renders die een prachtige visualisatie geven van uw project. Overtuig uw klanten met een levensechte 3D-afbeelding van hoe u project er zal uitzien. Wilt u meubels of details op maat laten maken voor uw pand, is dit zeker geen probleem voor ons professioneel team."
      />
      <Slider />
      <TitleAndText
        titleOrder="3"
        titleAnimation="fade-left"
        colOrder="2"
        textOrder="1"
        title="Service"
        text="Door onze persoonlijke approach creëren wij telkens grondplannen & 3D renders die de standaard overtreffen, zo krijgt uw woning de high-end aanpak die ze verdient van begin tot eind."
      />
    </div>
  )
}
