/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Flex, Text, Heading, Col, Button } from 'vr-horizon-ui'

import { mobileBreak } from '../../global'

const ButtonLink = styled(Button.withComponent(Link))(() => ({
  width: '55%',
  color: 'white',
}))

export const ImgAndText = props => {
  return (
    <Flex
      bg={props.bgColor}
      id={props.id}
      justifyContent="space-evenly"
      alignContent="center"
      css={{
        padding: `${props.paddingProp || '5vh 10vw'}`,
        textAlign: 'justify',
        zIndex: '4',
      }}>
      <Flex
        css={{
          maxWidth: '1500px',
          [mobileBreak]: {
            flexDirection: 'column',
          },
        }}>
        <Flex
          flex={0.4}
          order={props.textOrder}
          data-aos={props.textAnimation}
          flexDirection="column"
          justifyContent={props.justifyText || 'space-around'}
          css={{
            zIndex: '2',
          }}>
          <Heading flex={0.2} css={{ padding: '24px 0', textAlign: 'left' }}>
            {props.title}
          </Heading>
          <Text
            fontSize={{ small: 12, medium: 14, large: 16 }}
            flex={0.6}
            css={{
              lineHeight: '2',
              maxWidth: '50vw',
              [mobileBreak]: {
                maxWidth: '90vw',
              },
            }}>
            {props.text}
          </Text>
          <ButtonLink
            to={props.btnTo}
            bg={'primary'}
            css={{
              display: `${props.btnDisplay}`,
              marginTop: '42px',
            }}>
            {props.btnText}
          </ButtonLink>
        </Flex>

        <Col
          order={props.colOrder}
          flex={0.2}
          css={{
            [mobileBreak]: {
              display: 'none',
            },
          }}
        />

        <Flex
          flex={0.4}
          order={props.imgOrder}
          data-aos={props.imgAnimation}
          width={'30vw'}
          height={'42vh'}
          alt={props.imgAlt}
          css={{
            backgroundImage: `url(${props.img})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            alignSelf: 'flex-start',
            zIndex: '2',
          }}
        />
      </Flex>
    </Flex>
  )
}
