/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Card } from 'vr-horizon-ui'

export const ImgComponent = props => {
  return (
    <Flex justifyContent="center" width="100vw">
      <Flex
        width={props.imgW}
        height={props.imgH}
        alt={props.imgAlt}
        css={{
          backgroundImage: `url(${props.img})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `${props.imgPos}`,
          backgroundAttachment: `${props.parallax}`,
          alignSelf: 'center',
        }}
      />
    </Flex>
  )
}
