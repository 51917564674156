/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { bool } from 'prop-types'
import { StyledMenu } from './Menu.styled'

import { Icon } from 'vr-horizon-ui'
import { ReactComponent as Globe } from '../../components/feather/globe.svg'
import { ReactComponent as Home } from '../../components/feather/home.svg'
import { ReactComponent as Camera } from '../../components/feather/camera.svg'
import { ReactComponent as HouseMap } from '../../components/feather/map.svg'
import { ReactComponent as Square } from '../../components/feather/square.svg'
import { ReactComponent as Phone } from '../../components/feather/phone.svg'

export const Menu2 = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <Link className="vrHorizonTitle" to="/">
        Vr-Horizon
      </Link>

      <Link className="menuLink" to="/">
        <Icon
          icon={Home}
          color="#61abf0"
          size={'1.3rem'}
          className="mySvg"
          css={{
            marginRight: '1rem',
          }}
        />
        Home
      </Link>

      <Link className="menuLink" to="/foto-video">
        <Icon
          icon={Camera}
          color="#61abf0"
          size={'1.3rem'}
          className="mySvg"
          css={{
            marginRight: '1rem',
          }}
        />
        Foto-video
      </Link>
      <Link className="menuLink" to="/visualisatie">
        <Icon
          icon={HouseMap}
          color="#61abf0"
          size={'1.3rem'}
          css={{ marginRight: '1rem' }}
        />
        Visualisatie
      </Link>
      <Link className="menuLink" to="/info">
        <Icon
          icon={Globe}
          color="#61abf0"
          size={20}
          css={{ marginRight: '1rem' }}
        />
        360°
      </Link>
      <Link className="menuLink" to="/borden">
        <Icon
          icon={Square}
          color="#61abf0"
          size={20}
          css={{ marginRight: '1rem' }}
        />
        Borden
      </Link>
      <Link className="menuLink" to="/contact">
        <Icon
          icon={Phone}
          color="#61abf0"
          size={20}
          css={{ marginRight: '1rem' }}
        />
        Contact
      </Link>
    </StyledMenu>
  )
}

Menu2.propTypes = {
  open: bool.isRequired,
}
