/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Burger } from '../Burger'
import { Menu2 } from '../Menu2'

export const BurgerMenu = props => {
  return (
    <div ref={props.refProp}>
      <Burger open={props.open} setOpen={props.setOpen} />
      <Menu2 open={props.open} setOpen={props.setOpen} />
    </div>
  )
}
