import React from 'react'
import {
  IntroBanner,
  ImgAndText,
  TitleAndText,
  YoutubeVideo,
} from '../../components'
import { Slider } from './Slider'
import photo from '../../img/vr-horizon-borden-groot.jpg'
import photoBanner from '../../img/vr-horizon-borden-banner.jpg'

export const BoardsPage = props => {
  return (
    <div id="BoardsPage">
      <IntroBanner
        img={photoBanner}
        imgAlt={'vr-horizon-tours-banner'}
        bannerH={'40vh'}
        title={'IMMOBORDEN'}
        blurColor={'#7986CB'}
        imgPos={'center'}
      />
      <ImgAndText
        textOrder="3"
        textAnimation="fade-right"
        title="Juiste bord op juiste tijd aan juiste pand"
        text="Het totaal aanbod dat we beloven, komen we ook na: makelaarsborden maken/plaatsen
behoort ook tot onze service. We weten hoe belangrijk het is om zo’n borden op het juiste
moment, op de juiste plaats te installeren. Daarom dat u ook gratis toegang krijgt tot onze
online tool om de plaatsing op te volgen.
De borden worden met precisie geplaatst en zorgvuldig bewaard voor toekomstig gebruik. Ze
zijn bestendig tegen alle weersomstandigheden alsook verkleuring. Door onze ervaring in
outdoor vastgoedcommunicatie, kunnen wij u raad geven over de ideale locatie, grootte en
vorm van het bord."
        justifyText="center"
        btnTo="/"
        btnDisplay="none"
        colOrder="2"
        imgOrder="1"
        imgAnimation="fade-right"
        img={photo}
        imgAlt={'vr-horizon-real-estate'}
      />
      <TitleAndText
        titleOrder="3"
        titleAnimation="fade-left"
        colOrder="2"
        textOrder="1"
        title="Snel, stevig en goed voor het milieu"
        text="Innovatief zijn is niet zo maar een slogan, ook bij het beheren van borden kijken wij steeds
naar nieuwe bevestigingssystemen, nieuwe milieuvriendelijke inkten of een nieuwe
energiebesparende routeplanning.
Voorkeur voor bepaalde bevestiging? Bij ons heeft u de keuze. Ons foutenpercentage ligt laag.
Loopt er iets niet zoals het hoort? Gewoon even melden en wij lossen het op."
      />
    </div>
  )
}
