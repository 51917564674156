/** @jsx jsx */
import { useEffect } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Card, Flex, Icon } from 'vr-horizon-ui'
import { ReactComponent as Globe } from '../../components/feather/globe.svg'
import { ReactComponent as Camera } from '../../components/feather/camera.svg'
import { ReactComponent as HouseMap } from '../../components/feather/map.svg'

const ServiceItem = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '20vw',
  margin: '0 10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  textAlign: 'center',
  backgroundColor: 'white',
}))

export const Benefits = props => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        })
      })
    })
  })

  return (
    <Flex data-aos="fade-up" p={5} css={{ justifyContent: 'center' }}>
      <Flex
        justifyContent="space-around"
        flexDirection="row"
        maxWidth="1080px"
        css={{ zIndex: '3' }}>
        <ServiceItem p={3} shadow>
          <Icon icon={Globe} color="darks.1" size={64} />
          <h3>360° Beelden</h3>
        </ServiceItem>
        <ServiceItem p={3} shadow>
          <Icon icon={Camera} color="darks.1" size={64} />
          <h3>Vastgoed Fotografie</h3>
        </ServiceItem>
        <ServiceItem p={3} shadow>
          <Icon icon={HouseMap} color="darks.1" size={64} />
          <h3>Plattegronden </h3>
        </ServiceItem>
      </Flex>
    </Flex>
  )
}
