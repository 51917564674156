/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from 'vr-horizon-ui'

export const YoutubeVideo = props => {
  return (
    <Flex className="containerWhiteSpace" justifyContent="center">
      <iframe
        title="vr-horizon-video-case"
        data-aos={props.animation}
        css={{
          width: `${props.videoW}`,
          height: `${props.videoH}`,
          frameborder: '0',
        }}
        src={props.videoUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
        frameBorder="0"
        muted="muted"
      />
    </Flex>
  )
}
