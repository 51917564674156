/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Text, Heading, Col } from 'vr-horizon-ui'

const mobileBreak = `@media (max-width: 1000px)`

export const TitleAndText = props => {
  return (
    <Flex
      justifyContent="space-evenly"
      alignContent="center"
      css={{ padding: '15vh 32px' }}>
      <Flex
        css={{
          maxWidth: '1500px',
          [mobileBreak]: {
            flexDirection: 'column',
          },
        }}>
        <Heading
          data-aos={props.titleAnimation}
          css={{
            padding: '24px 0',
            order: `${props.titleOrder}`,
            flex: '0.4',
          }}>
          {props.title}
        </Heading>
        <Col order={props.colOrder} flex={0.2} />
        <Text
          //TEXT BLOK animation
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-cubic"
          data-aos-delay="400"
          css={{
            lineHeight: '2',
            maxWidth: '60vw',
            order: `${props.textOrder}`,
            flex: '0.4',
            textAlign: 'justify',
            [mobileBreak]: {
              order: '6',
              maxWidth: '90vw',
            },
          }}>
          {props.text}
        </Text>
      </Flex>
    </Flex>
  )
}
