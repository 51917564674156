/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ImgAndText } from '../../components'
import photo from '../../img/vr-horizon-construction.jpg'

export const ConstructionIllustration = () => {
  return (
    <ImgAndText
      textOrder="1"
      title="Deze pagina is onder constructie"
      text="Onze developers zijn volop bezig met de website te vernieuwen. Hierdoor zullen sommige pagina's nog niet af zijn. Volg onze nieuws letter om op de hoogte te blijven van nieuwe ontwikkelingen."
      btnTo="/"
      btnText="BACK"
      colOrder="2"
      imgOrder="3"
      img={photo}
      imgAlt={'photoAbout something cool'}
    />
  )
}
