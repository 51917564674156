import React from 'react'
import {
  IntroBanner,
  ImgAndText,
  TitleAndText,
  YoutubeVideo,
} from '../../components'
import { Slider } from './Slider'
import photo from '../../img/vr-horizon-photo-case.jpg'
import photoBanner from '../../img/vr-horizon-foto-banner.jpg'

export const PhotoAndVideoPage = props => {
  return (
    <div id="PhotoAndVideoPage">
      <IntroBanner
        img={photoBanner}
        imgAlt={'vr-horizon-tours-banner'}
        bannerH={'40vh'}
        title={'Foto & Video'}
        blurColor={'#7986CB'}
        imgPos={'center'}
      />
      <ImgAndText
        textOrder="3"
        textAnimation="fade-right"
        title="Vastgoedfotografie & Videografie"
        text="Het team van VR-Horizon is als geen ander gespecialiseerd in vastgoedfotografie & videografie. Het in beeld brengen van een woning is slechts een onderdeel van wat onze professionals tot hun dagtaak rekenen, uw woning laten spreken en tot zijn recht komen is hun ware kunst. Met de nieuwste apparatuur & technologie die voorhanden is, brengen zij uw woning digitaal tot leven."
        justifyText="center"
        btnTo="/"
        btnDisplay="none"
        colOrder="2"
        imgOrder="1"
        imgAnimation="fade-right"
        img={photo}
        imgAlt={'vr-horizon-real-estate'}
      />
      <Slider />
      <TitleAndText
        titleOrder="3"
        titleAnimation="fade-left"
        colOrder="2"
        textOrder="1"
        title="Kwaliteit"
        text="Niet alleen het bieden van hoge kwaliteit in het afgeleverde product maar ook uitstekende service rekenen wij tot onze prioriteiten. Wij zijn dan ook pas tevreden als u dat ook bent, met dit adagio in de hand kan u met een gerust gemoed uw verkoop aanvatten. Onze klanten optimaal van dienst zijn is waar VR-Horizon garant voor staat."
      />
      <YoutubeVideo
        animation="fade-up"
        videoW="100vw"
        videoH="50vh"
        videoUrl="https://www.youtube.com/embed/chtvzEQmugw?loop=1&modestbranding=1"
      />
    </div>
  )
}
