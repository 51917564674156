/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Flex } from 'vr-horizon-ui'
import { ImgAndText } from '../../components'

import photo from '../../img/vr-horizon-camera-2.gif'

export const VRrondleiding = () => {
  return (
    <Flex
      css={{
        position: 'relative',
        overflow: 'hidden',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8faf8',
      }}>
      <ImgAndText
        bgColor={'#f8faf8'}
        textOrder="1"
        textAnimation="fade-right"
        title="Virtuele rondleidingen"
        id={'vrTours'}
        text="Kwaliteitsfoto’s in HDR verwerken tot een 360°-tour is onze specialiteit. Het steeds beschikken over de nieuwste technologie & apparatuur beschouwen wij als ons paradepaardje. Zodanig wordt de virtuele rondleiding van uw pand steeds een schot in de roos. Door het integreren van onze high-end technologie met een professionele correcte approach bouwen we dan ook steeds samen met onze klant aan een kwaliteitsvol eindresultaat."
        btnTo="/info"
        btnText="Info"
        colOrder="2"
        imgOrder="3"
        imgAnimation="fade-right"
        img={photo}
        imgAlt={'photoAbout something cool'}
      />
    </Flex>
  )
}
