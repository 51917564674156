/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ImgAndText } from '../../components'
import photo from '../../img/vr-horizon-gear.jpg'

export const PhotoAndVideo = () => {
  return (
    <ImgAndText
      textOrder="1"
      textAnimation="fade-right"
      title="Foto- & Videografie"
      id={'photoAndVideo'}
      text="Het team van VR-Horizon is als geen ander gespecialiseerd in vastgoedfotografie & videografie. Met de nieuwste fotografische apparatuur & technologie die voorhanden is, brengen wij uw woning digitaal tot leven. Uw woning zo goed mogelijk profileren op de markt is ons objectief!"
      btnTo="/foto-video"
      btnText="Info"
      colOrder="2"
      imgOrder="3"
      imgAnimation="fade-right"
      img={photo}
      imgAlt={'vr-horizon-foto-and-video'}
      bgColor={'#f7f7f7'}
    />
  )
}
