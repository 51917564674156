import React from 'react'
import { ConstructionIllustration } from './ConstructionIllustration'

export const Construction = props => {
  return (
    <div id="construction">
      <ConstructionIllustration />
    </div>
  )
}
