/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ImgAndText } from '../../components'
import photo from '../../img/insta360-pro.png'

export const Camera = () => {
  return (
    <ImgAndText
      paddingProp="15vh 10vw"
      textOrder="3"
      textAnimation="fade-right"
      btnDisplay="none"
      title="360° Fotografie"
      id={'photoAndVideo'}
      text="Kwaliteitsfoto’s in HDR verwerken tot een 360°-tour is onze specialiteit. De technologische markt ontwikkelt zich verbazend snel, de professionals van VR-Horizon zetten dan ook maximaal in om steeds te beschikken over de nieuwste technologie. Zo behalen onze virtuele rondleidingen steeds de hoogste kwaliteitsnormen."
      colOrder="2"
      imgOrder="1"
      imgAnimation="fade-right"
      img={photo}
      btnTo="/"
      display="none"
      imgAlt={'vr-horizon-gear'}
    />
  )
}
