import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = ({ open, setOpen }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setOpen(false)
  }, [pathname])

  return null
}
