/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TitleAndText } from '../../components'

export const Custom = () => {
  return (
    <TitleAndText
      titleOrder="3"
      titleAnimation="fade-left"
      colOrder="2"
      textOrder="1"
      title="Service"
      text="Wij zijn van oordeel dat het voortdurend vernieuwen van onze apparatuur en bijsturen van onze technologie onmisbaar is in de markt van vandaag.  Wij doen dit dan ook met het oog op ons tevreden cliënteel, opdat wij uw huis steeds optimaal kunnen visualiseren."
    />
  )
}
