/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Flex } from 'vr-horizon-ui'
import { Link } from 'react-router-dom'
import logo from '../../img/vr-horizon-logo.png'

const MenuLink = styled(Link)(props => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '10vw',
  textDecoration: 'none',
  color: 'white',
}))

/* const MenuA = styled('a')(() => ({
  display: 'flex',

  alignItems: 'center',
  height: '100%',
  width: '10vw',
  textDecoration: 'none',
  color: 'white',
})) */

export const Menu = () => {
  /*   useEffect(() => {
    //MOET NOG VIA ROUTER WANEER NIET OP HOME
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()
        if (document.location.pathname === '/') {
          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          })
        } else {
          window.location.href = '/#cases'
        }
      })
    })
  }) */

  return (
    <Flex>
      <Flex
        height="60px"
        width="100vw"
        css={{
          position: 'fixed',
          zIndex: '420',
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
        }}
        bg="primary"
        color="lights.4"
        display="flex"
        justifyContent="space-around"
        alignItems="center">
        <Link to="/">
          <img
            src={logo}
            alt="vr-horizon-logo"
            height="30px"
            css={{
              alignSelf: 'flex-start',
              padding: '0 20px',
            }}
          />
        </Link>

        <MenuLink to={'/foto-video'}>Foto-Video</MenuLink>
        <MenuLink to={'/visualisatie'}>Visualisatie</MenuLink>
        <MenuLink to={'/info'}>360°</MenuLink>
        <MenuLink css={{ alignSelf: 'right' }} to={'/contact'}>
          Contact
        </MenuLink>
      </Flex>
      <Flex height="60px" />
    </Flex>
  )
}
