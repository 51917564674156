/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CrookedEffect, ImgAndText } from '../../components'
import photo from '../../img/vr-horizon-crm.png'
import { Flex } from 'vr-horizon-ui'

export const Crm = () => {
  return (
    <Flex
      css={{
        position: 'relative',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#f7f7f7',
      }}>
      <ImgAndText
        textOrder="1"
        textAnimation="fade-left"
        title="Online platform"
        text="Al onze klanten kunnen gebruik maken van ons online platform. Op dit platform kan je nieuwe opdrachten doorgeven als ook zien hoe ver het staat met jouw opdracht. Ook word je automatisch op de hoogte gebracht wanneer jouw opdracht voltooid is. Je kan je bestanden online vinden op het platform en ook via mail ontvangen."
        btnTo="/construction"
        btnText="Info"
        colOrder="2"
        imgOrder="3"
        imgAnimation="fade-left"
        img={photo}
        imgAlt={'photoAbout something cool'}
        justifyText="center"
        btnDisplay="none"
      />
      <CrookedEffect containerColor="white" />
    </Flex>
  )
}
