/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'

import { Flex, Heading, Text } from 'vr-horizon-ui'
import { IntroBanner } from '../../components'

import photo from '../../img/vr-horizon-contact-banner.jpg'

const TextElement = styled(Text)(() => ({
  padding: '10px 0',
}))

export const Contact = props => {
  return (
    <div id="Contact">
      <IntroBanner
        img={photo}
        imgAlt={'vr-horizon-tours-banner'}
        bannerH={'30vh'}
        title={'Contact'}
        blurColor={'royalblue'}
      />

      <Heading css={{ padding: '5vh 10vw' }}>Contactgegevens</Heading>
      <Flex
        justifyContent="flex-start"
        flexDirection="row"
        css={{ width: '100vw', padding: '0 10vw' }}>
        <Flex flexDirection="column">
          <TextElement>BVBA VR-Horizon </TextElement>
          <TextElement> Korenmarkt 47 2°v </TextElement>
          <TextElement> 2800 Mechelen</TextElement>
        </Flex>
        <Flex flexDirection="column" css={{ padding: '0 5vw' }}>
          <TextElement>+32(0)493/32 43 73</TextElement>
          <TextElement>info@vr-horizon.be</TextElement>
          <TextElement>BE 0725.934.043</TextElement>
        </Flex>
      </Flex>
      <Flex justifyContent="center" css={{ width: '100vw' }}>
        <iframe
          title="High end Real-estate"
          css={{ width: '90%', height: '40vh', padding: '5vh 10vw', border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d970.8852426829973!2d4.474884574470496!3d51.0243890411085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e5cf6cecda5d%3A0x619996c15349680a!2sKorenmarkt+47%2C+2800+Mechelen!5e0!3m2!1sen!2sbe!4v1499438219025"
        />
      </Flex>
    </div>
  )
}
