/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Flex } from 'vr-horizon-ui'
import { CrookedEffect, ImgAndText } from '../../components'

import photo from '../../img/vis-img.png'

export const Visualisation = () => {
  return (
    <Flex
      css={{
        position: 'relative',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}>
      <ImgAndText
        textOrder="3"
        textAnimation="fade-left"
        title="Visualisatie"
        id="visualisatie"
        text="Uw grondplan verwerken doen we met behulp van Floorplanner, zo maken we een structurele plattegrond. Ook in ons ‘basisplan’ implementeren wij personalisering zodat uw bedrijf een plategrond heeft in uw eigen huisstijl met logo. Beschikt u reeds over afmetingen of wenst u dat wij dit voor u in beeld brengen, beide opties worden bij VR-Horizon met een glimlach tot een mooi eindresultaat gebracht."
        btnTo="/visualisatie"
        btnText="Info"
        colOrder="2"
        imgOrder="1"
        imgAnimation="fade-left"
        img={photo}
        imgAlt={'vr-horizon-visualisation'}
      />
      <CrookedEffect containerColor="#f7f7f7" />
    </Flex>
  )
}
