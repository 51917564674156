import React from 'react'
import { Banner } from './Banner'
import { Services } from './Services'
import { VRrondleiding } from './VRrondleiding'
import { PhotoAndVideo } from './PhotoAndVideo'
import { Visualisation } from './Visualisation'
import { Boards } from './Boards'
import { Crm } from './Crm'

//MAILLIST BLogFeed moeten nog geficxt worden

export const Homepage = props => {
  return (
    <div id="home">
      <Banner />
      <Services />
      <PhotoAndVideo />
      <Visualisation />
      <VRrondleiding />
      <Boards />
      <Crm />
    </div>
  )
}
