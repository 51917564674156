/** @jsx jsx */
import { jsx } from '@emotion/core'

import './style.css'

export const Unsupported = () => {
  return (
    <div className="unsupported">
      <div className="grid-tools FullSite">
        <div className="container">
          <div className="container-wrapper">
            <div className="container-full-column">
              <div className="unsupported-browser">
                <h1>
                  Please upgrade your browser in order to use Vr-Horizon.be
                </h1>
                <p>
                  We built this website using the latest technology which
                  improves the look of the site, increases the speed of the
                  site, and gives you a better experience with new features and
                  functions. Unfortunately, your browser does not support these
                  technologies.
                </p>
                <h2>
                  Please download one of these free and up-to-date browsers:
                </h2>
                <ul>
                  <li>
                    <a href="https://www.mozilla.com/firefox/" target="_blank">
                      Firefox
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.google.com/chrome/browser/desktop/"
                      target="_blank">
                      Chrome
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                      target="_blank">
                      Microsoft Edge
                    </a>
                  </li>
                </ul>
                <hr></hr>
                <ul>
                  <li>
                    If you can't change your browser because of compatibility
                    issues, think about installing a second browser for
                    utilization of this site and keep the old one for
                    compatibility.
                  </li>
                </ul>
              </div>

              <div className="unsupported-message"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
