import React from 'react'
import { ImgComponent, TourEmbed, TitleAndText } from '../../components'
import { Camera } from './Camera'
import { Custom } from './Custom'
import { Banner } from './Banner'
import { Benefits } from './Benefits'

import photo from '../../img/vr-horizon-360-img.jpg'

export const VRpage = props => {
  return (
    <div id="Vrpage">
      <Banner />
      <Camera />
      <ImgComponent
        img={photo}
        imgAlt="vr-horizon-tour-banner"
        imgH="30vh"
        imgW="100%"
        imgPos="center"
        parallax="fixed"
      />
      <Custom />
      <Benefits btnDisplay="none" />
      <TourEmbed />
      <TitleAndText
        titleOrder="1"
        titleAnimation="fade-right"
        colOrder="2"
        textOrder="3"
        title="The total package"
        text="In onze tours vindt u ook foto’s en grondplannen terug als u deze zou hebben besteld bij ons. Zo kan een mogelijke koper van uw eigendom ook de ruimtes of opmetingen in detail bekijken terwijl deze door het virtueel bezoek gaat."
      />
    </div>
  )
}
