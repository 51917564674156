/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Heading } from 'vr-horizon-ui'

export const IntroBanner = props => {
  return (
    <Flex
      alt={props.imgAlt}
      justifyContent="center"
      alignItems="center"
      css={{
        position: 'relative',
        backgroundImage: `url(${props.img})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `${props.imgPos}`,
        //backgroundAttachment: 'fixed',
        width: '100vw',
        height: `${props.bannerH}`,
        overflow: 'hidden',
      }}>
      <Heading
        color="white"
        css={{
          zIndex: '4',
          position: 'absolute',
        }}>
        {props.title}
      </Heading>

      <div
        css={{
          position: 'absolute',
          opacity: '0.5',
          zIndex: '3',
          width: '100vw',
          height: '100vh',
          left: 0,
          right: 0,
          backgroundColor: `${props.blurColor}`,
        }}
      />
    </Flex>
  )
}
