/** @jsx jsx */
import { jsx } from '@emotion/core'
import { IntroBanner } from '../../components'
import photo from '../../img/vr-horizon-360-banner.jpg'

export const Banner = () => {
  return (
    <IntroBanner
      img={photo}
      imgPos={'center'}
      imgAlt={'vr-horizon-tours-banner'}
      bannerH={'40vh'}
      title={'Virtuele rondleidingen'}
      blurColor={'#78909C'}
    />
  )
}
