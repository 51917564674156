/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Card, Heading } from 'vr-horizon-ui'

const TileCard = styled(Card.withComponent('a'))(() => ({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  textDecoration: 'none',
  zIndex: 3,
  opacity: 0.9,
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  '&:hover': {
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    opacity: 1,
  },
}))

export const BlogTile = props => {
  return (
    <TileCard
      alt={props.imgAlt}
      href={props.externalLink}
      target="_blank"
      css={{
        backgroundImage: `url(${props.img})`,
        backgroundPosition: `${props.imgPos}`,
      }}
      p={2}
      shadow
      justifyContent="space-around"
      flexDirection="column"
      width="20%"
      height="400px">
      <Heading color="white" css={{ userSelect: 'none' }}>
        {props.title}
      </Heading>
    </TileCard>
  )
}
