/** @jsx jsx */
import { jsx } from '@emotion/core'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import { Card } from 'vr-horizon-ui'

const SlideContainer = styled(Card)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  width: '100vw',
}))

const SliderImg = styled('div')(() => ({
  height: '70vh',
  width: '100vw',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}))

const Arrow = styled('button')(({ direction }) =>
  direction === 'left'
    ? {
        left: 32,
        zIndex: 1,
      }
    : { right: 32, zIndex: 1 },
)

export const SlickSlider = props => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow direction="left" />,
    nextArrow: <Arrow direction="right" />,
  }

  const sideImages = props.slideImgs
  const SlideItems = sideImages.map(item => {
    return (
      <SlideContainer key={item.containerKey}>
        <SliderImg
          key={item.photoKey}
          css={{ backgroundImage: `url(${item.photo})` }}
        />
      </SlideContainer>
    )
  })

  return (
    <Slider
      className="containerWhiteSpace"
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
      }}
      {...settings}>
      {SlideItems}
    </Slider>
  )
}
