/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from 'vr-horizon-ui'

export const TourEmbed = props => {
  return (
    <Flex width="100vw">
      <iframe
        title="Tour for banner"
        src="https://tours.vr-horizon.be/case-1"
        id="ifr"
        css={{
          width: '100%',
          height: '70vh',
          border: 'none',
          padding: '5vh 10vw',
        }}
      />
    </Flex>
  )
}
