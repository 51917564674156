/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, Flex, Icon } from 'vr-horizon-ui'
import { Link } from 'react-router-dom'
import logo from '../../img/vr-horizon-logo.png'
import { ReactComponent as Facebook } from '../feather/facebook.svg'

export const Footer = () => {
  return (
    <Flex
      width={'100vw'}
      flexDirection="column"
      alignItems="center"
      bg={'#90cafa'}
      css={{
        paddingTop: '30px',
      }}>
      <hr width="72%" css={{ border: '1px solid white' }} />
      <Flex
        p={2}
        width="82%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around">
        <Link to="/">
          <img
            src={logo}
            alt="vr-horizon-logo"
            height="30px"
            css={{
              alignSelf: 'flex-start',
            }}
          />
        </Link>

        <Text color="white">© 2020 VR-HORIZON. All rights reserved</Text>
        <a
          href="https://www.facebook.com/vrhorizon360/"
          target="_blank"
          rel="noopener noreferrer">
          <Icon icon={Facebook} color="white" size={32} />
        </a>
      </Flex>
    </Flex>
  )
}
