/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Flex, Text, Heading } from 'vr-horizon-ui'
import logo from '../../img/vr-horizon-logo.png'

const IFrame = styled('iframe')({
  height: '62vh',
  width: '100vw',
  pointerEvents: 'none',
  border: 'none',
})

// SVG ANIMATION
const transformPath1 = keyframes`
   to {
    transform:  scale(1.4) translate(0, 0);
  }
`
const transformPath2 = keyframes`
   to {
    stroke-dashoffset: 1000;
  }
`

export const Banner = () => {
  return (
    <Flex
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      css={{ position: 'relative' }}>
      <IFrame src="https://special.vr-horizon.be/banner/" />

      <Flex
        flexDirection="column"
        css={{
          position: 'absolute',
          top: '30%',
          margin: '0 20px',
          alignContent: 'center',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '92vw',
        }}>
        <Flex
          alt="vr-horizon-logo"
          css={{
            width: '120px',
            height: '120px',
            backgroundPosition: 'center',
            backgroundImage: `url(${logo})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            zIndex: 2,
          }}
        />
        <Heading color="white" mb={4} fontWeight={100}>
          VR-HORIZON
        </Heading>
        <Text
          mb={4}
          color="white"
          css={{
            maxWidth: '420px',
            textAlign: 'center',
          }}>
          Your partner for real estate photography
        </Text>
      </Flex>

      <svg
        width="100vw"
        height={`${100 / 8}vw`}
        css={{
          position: 'absolute',
          bottom: -1,
          left: 0,
          right: 0,
          fill: 'white',
        }}
        viewBox="100 -50 700 50">
        <path
          css={css`
            animation: ${transformPath1} 12s ease infinite alternate;
            transform: translate(10px, -30px);
          `}
          d="M 0 0 C 150 -15 250 -15 400 0 C 550 15 650 15 800 0 L 800 50 L 0 50 L 0 0"
        />
        <path
          css={css`
            animation: ${transformPath2} 18s ease infinite alternate;
          `}
          stroke="#000000"
          strokeDasharray="100"
          strokeWidth="4"
          d="M 0 0 C 0 0 0 0 0 0 C 0 0 0 0 0 0 L 0 0 L 0 0 L 0 0"
        />
      </svg>
    </Flex>
  )
}
