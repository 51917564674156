import styled from 'styled-components'
import { mobileBreak } from '../../global'

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: #0078e7;
  height: 100vh;
  text-align: left;
  padding: 0 0 0 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 99;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};

  ${[mobileBreak]} {
    width: 100%;
  }

  a {
    padding: 1rem 0.5rem;
    color: #61abf0;
    text-decoration: none;
    transition: color 0.3s linear;
    width: 100%;
    border-radius: 6px 0 0 6px;
    ${[mobileBreak]} {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .menuLink:hover {
    background-color: #61abf0;
    color: white;
    transition: none;
  }

  .menuLink:hover svg {
    stroke: white;
  }

  .vrHorizonTitle {
    color: white;
    margin-top: 15vh;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: 400;

    display: block;
    position: relative;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    box-sizing: border-box;
  }
`
