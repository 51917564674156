import React from 'react'
import { SlickSlider } from '../../components'
import photo1 from '../../img/vr-horizon-high-renders-1.jpg'
import photo2 from '../../img/vr-horizon-high-renders-2.jpg'
import photo3 from '../../img/vr-horizon-high-renders-3.jpg'
import photo4 from '../../img/vr-horizon-high-renders-4.jpg'

export const Slider = props => {
  return (
    <SlickSlider
      slideImgs={[
        {
          photo: photo1,
          containerKey: 'photo-1-container',
          photoKey: 'photo-1',
        },
        {
          photo: photo2,
          containerKey: 'photo-2-container',
          photoKey: 'photo-2',
        },
        {
          photo: photo3,
          containerKey: 'photo-3-container',
          photoKey: 'photo-3',
        },
        {
          photo: photo4,
          containerKey: 'photo-4-container',
          photoKey: 'photo-4',
        },
      ]}
    />
  )
}
