import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import AOS from 'aos'

import 'aos/dist/aos.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { ThemeProvider } from 'emotion-theming'
import { GlobalStyles } from './global'
import { createTheme } from 'vr-horizon-ui'

import { BrowserRouter as Router } from 'react-router-dom'

AOS.init({
  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 100, // offset (in px) from the original trigger point
  duration: 600, // values from 0 to 3000, with step 50ms
  easing: 'ease-in', // default easing for AOS animations
})
ReactDOM.render(
  <ThemeProvider theme={createTheme({ colors: { myColor: 'red' } })}>
    <GlobalStyles />
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
