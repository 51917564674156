/** @jsx jsx */
import { useEffect } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Card, Flex, Button, Icon } from 'vr-horizon-ui'
import { ReactComponent as Globe } from '../../components/feather/globe.svg'
import { ReactComponent as Aperture } from '../../components/feather/aperture.svg'
import { ReactComponent as Eye } from '../../components/feather/eye.svg'

const mobileBreak = `@media (max-width: 1000px)`

const ServiceItem = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '20vw',
  margin: '0 10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  textAlign: 'center',
  backgroundColor: 'white',
  [mobileBreak]: {
    padding: '12px',
  },
}))

const ServiceTitle = styled('p')(() => ({
  fontWeight: 'bold',
}))

const ServiceTitleIcon = styled(Icon)(() => ({
  [mobileBreak]: {
    width: '44px',
    height: '44px',
  },
}))

const ButtonLink = styled(Button.withComponent('a'))(() => ({
  color: 'white',
}))

export const Services = () => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        })
      })
    })
  })

  return (
    <Flex p={5} css={{ justifyContent: 'center' }}>
      <Flex
        data-aos="fade-up"
        justifyContent="space-around"
        flexDirection="row"
        maxWidth="1080px"
        css={{ zIndex: '3' }}>
        <ServiceItem p={3} shadow>
          <ServiceTitleIcon icon={Aperture} color="darks.1" size={64} />
          <ServiceTitle>Foto- & Videografie </ServiceTitle>
          <ButtonLink
            href="#photoAndVideo"
            bg={'primary'}
            color={'white'}
            size={'small'}>
            Info
          </ButtonLink>
        </ServiceItem>
        <ServiceItem p={3} shadow>
          <ServiceTitleIcon icon={Eye} color="darks.1" size={64} />
          <ServiceTitle>Visualisatie </ServiceTitle>
          <ButtonLink
            href="#visualisatie"
            bg={'primary'}
            color={'white'}
            size={'small'}>
            Info
          </ButtonLink>
        </ServiceItem>
        <ServiceItem p={3} shadow>
          <ServiceTitleIcon icon={Globe} color="darks.1" size={64} />
          <ServiceTitle>Virtuele rondleidingen </ServiceTitle>
          <ButtonLink
            href="#vrTours"
            bg={'primary'}
            color={'white'}
            size={'small'}>
            Info
          </ButtonLink>
        </ServiceItem>
      </Flex>
    </Flex>
  )
}
