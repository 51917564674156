import React, { useState, useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import RenderInBrowser from 'react-render-in-browser'

import {
  ScrollToTop,
  BurgerMenu,
  Footer,
  useOnClickOutside,
} from './components'

import { Homepage } from './Pages/Home'
import { VRpage } from './Pages/VR'
import { PhotoAndVideoPage } from './Pages/PhotoAndVideo'
import { VisualisationPage } from './Pages/Visualisation'
import { BoardsPage } from './Pages/Boards'
import { Contact } from './Pages/Contact'
import { Construction } from './Pages/Construction'
import { Unsupported } from './Pages/Unsupported'

function App() {
  const [open, setOpen] = useState(false)
  const node = useRef()
  useOnClickOutside(node, () => setOpen(false))
  return (
    <div id="app">
      <Helmet>
        <title>VR-HORIZON | VR-Rondleidingen | 3D Projecten</title>
        <meta charSet="utf-8" />
        <meta http-equiv="language" content="NL" />
        <meta
          name="description"
          content="Vr-Horizon is een Belgische start-up die bedrijven helpt met hun online aanwezigheid. Wij bieden u VR- en 360° rondleidingen, webdesign en 3D-projecten aan.."
        />
      </Helmet>

      <RenderInBrowser ie only>
        <Unsupported></Unsupported>
      </RenderInBrowser>
      <RenderInBrowser except ie>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route path="/info">
            <VRpage />
          </Route>
          <Route path="/foto-video">
            <PhotoAndVideoPage />
          </Route>
          <Route path="/visualisatie">
            <VisualisationPage />
          </Route>
          <Route path="/borden">
            <BoardsPage />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/construction">
            <Construction />
          </Route>
        </Switch>
        <BurgerMenu refProp={node} open={open} setOpen={setOpen} />
        <ScrollToTop open={open} setOpen={setOpen} />
        <Footer />
      </RenderInBrowser>
    </div>
  )
}

export default App
