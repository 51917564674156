/** @jsx jsx */
import { jsx } from '@emotion/core'

export const CrookedEffect = props => {
  return (
    <div
      css={{
        backgroundColor: `${props.containerColor}`,
        width: '200vw',
        height: '8vh',
        top: '-4vh',
        position: 'absolute',
        zIndex: 3,
        transform: `rotate(2deg)`,
      }}
    />
  )
}
